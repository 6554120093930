<template>
    <li
        class="nav-item nav-drop-item cart"
        @mouseover="openPopup()"
        @mouseleave="closePopup()"
    >
        <router-link
            :to="{ name: 'CosulMeu' }"
            variant="text"
            class="btn icon link"
            aria-label="Cosul meu"
        >
            <shopping-cart-icon />
            <span
                v-if="count > 0"
                class="count"
            >{{ count }}</span>
        </router-link>
        <navbar-cart-dropdown :active="showPopup" />
    </li>
</template>

<script>
    import ShoppingCartIcon from 'vue-feather-icons/icons/ShoppingCartIcon';
    import { mapState } from 'vuex';

    import NavbarCartDropdown from './NavbarCartDropdown';

    export default {
        name: 'CartItemNavbar',
        components: {
            ShoppingCartIcon,
            NavbarCartDropdown,
        },
        data() {
            return {
                count: 0,
                showPopup: false,
                popupReached: false,
            };
        },
        computed: {
            ...mapState('cart', ['total']),
        },
        methods: {
            initialize() {
                this.count = this.total ? this.total.count : 0;
            },
            closePopup() {
                this.popupReached = false;
                setTimeout(() => {
                    if (!this.popupReached) {
                        this.showPopup = false;
                    }
                }, 150);
            },
            openPopup() {
                if(this.$route.name != 'CosulMeu'){
                    this.showPopup = true;
                    this.popupReached = true;
                }
            },
        },
        mounted() {
            this.initialize();
        },
        watch: {
            total: {
                handler() {
                    this.initialize();
                },
                deep: true,
            },
            $route() {
                this.showPopup = false;
            },
        },
    };
</script>
<style scoped lang="scss">
.cart {
  .count {
    background-color: $primary;
  }
}
</style>
