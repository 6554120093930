<template>
    <div
        v-if="active && !isMobile"
        class="navbar-dropdown cart"
    >
        <div class="drop-content">
            <div
                v-for="product in products"
                :key="`drop-item-${product.code}`"
                class="item d-flex"
            >
                <router-link :to="{ name: 'Produs', params: { slug: product.slug } }">
                    <div class="image">
                        <img
                            :src="product.thumbnail"
                            :alt="product.name"
                        >
                    </div>
                </router-link>
                <div class="content">
                    <div class="d-flex drop-head">
                        <router-link
                            :to="{ name: 'Produs', params: { slug: product.slug } }"
                        >
                            <p class="sm-sbold title">
                                {{ product.name }}
                            </p>
                        </router-link>
                        <x-icon
                            class="close"
                            @click="removeProduct(product.id)"
                        />
                    </div>
                    <div class="d-flex info">
                        <p class="sm-sbold quantity">
                            x {{ product.quantity }}
                        </p>
                        <p
                            v-dompurify-html="price(product)"
                            class="sm-sbold"
                        />
                    </div>
                </div>
            </div>
            <div
                v-for="voucher in voucherItems"
                :key="`drop-item-${voucher.value}`"
                class="item d-flex"
            >
                <div class="image voucher">
                    <router-link :to="{ name: 'Voucher' }">
                        <img
                            :src="voucher.thumbnail"
                            :alt="voucher.name"
                        >
                    </router-link>
                </div>
                <div class="content">
                    <div class="d-flex drop-head">
                        <router-link :to="{ name: 'Voucher' }">
                            <p class="sm-sbold title">
                                {{ voucher.name }}
                            </p>
                        </router-link>
                        <x-icon
                            class="close"
                            @click="removeVoucher(voucher.value)"
                        />
                    </div>
                    <div class="d-flex info">
                        <p class="sm-sbold quantity">
                            x {{ voucher.quantity }}
                        </p>
                        <p
                            v-dompurify-html="price(voucher)"
                            class="sm-sbold"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="drop-footer">
            <div
                v-if="!isEmpty"
                class="total d-flex"
            >
                <div class="left d-flex">
                    <p>{{ $t('header.dropdown.subTotal') }}</p>
                    <p class="sm-sbold">
                        {{ totalText }}
                    </p>
                </div>
                <div class="right">
                    <p
                        v-dompurify-html="formatPrice(total.sub_total)"
                        class="sm-sbold"
                    />
                </div>
            </div>
            <div
                v-else
                class="empty"
            >
                <div class="d-flex cart">
                    <p class="sm-sbold">
                        {{ $t('header.dropdown.noItemsInCart') }}
                    </p>
                </div>
            </div>
        </div>

        <div class="drop-action">
            <router-link :to="{ name: 'CosulMeu' }">
                <p class="sm-sbold">
                    {{ $t('header.dropdown.seeCartDetails') }}
                </p>
            </router-link>
        </div>
    </div>
</template>

<script>
    import XIcon from 'vue-feather-icons/icons/XIcon';
    import { mapActions, mapState } from 'vuex';

    import { splitedPrice } from '@/services/getSplitedPrice';

    export default {
        name: 'NavbarCartDropdown',
        components: {
            XIcon,
        },

        props: {
            active: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapState('cart', ['total', 'products', 'vouchers']),
            isEmpty() {
                return this.total.count == 0;
            },
            totalText() {
                let text = '';
                if (this.total.count == 1) {
                    text =
                        '(1 ' + this.$t('header.dropdown.singleProductQuantityLabel') + ')';
                } else {
                    text =
                        '(' +
                        this.total.count +
                        ' ' +
                        this.$t('header.dropdown.multipleProductQuantityLabel') +
                        ')';
                }
                return text;
            },
            voucherItems() {
                return this.vouchers;
            },
            isMobile() {
                return this.$screen.breakpoint == 'md';
            },
        },
        methods: {
            ...mapActions('cart', {
                removeFromCart: 'removeItem',
            }),
            removeProduct(productID) {
                this.removeFromCart({ itemId: productID, isVoucher: false });
            },
            removeVoucher(value) {
                this.removeFromCart({ itemId: value, isVoucher: true });
            },
            price(product) {
                return splitedPrice(product.total_price);
            },
            formatPrice(price) {
                return splitedPrice(price);
            },
        },
    };
</script>
